import { Component, OnInit,ViewChild,OnDestroy } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { DashboardService } from "../../services/dashboard.service";
import { Tools } from "src/app/modules/tools";
import { Chart } from "angular-highcharts";
import { KnobModel } from "../../lib/knob.model";
import { DemoModel } from "./demo.model";
import {Router} from '@angular/router';
import { StationsService } from "src/app/services/Stations.Service";
import { StationModel } from "src/app/models/station.model";
import { ChartDataSets, ChartLegendLabelItem } from "chart.js";
import { ReadtsValue } from "src/app/models/common.model";
import { Observable, interval, Subscribable } from "rxjs";
import { ActivationStart, ActivationEnd } from "@angular/router";
import { ManageChargerComponent } from "../manage-charger/manage-charger.component";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { PortModel } from "src/app/models/chargerPort.model";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(ManageChargerComponent) managechargerComp: ManageChargerComponent;
  public pieChartLabels: string[] = ["Offline", "Online"];
  public pieChartData: number[] = [100, 390];
  public doughnutChartLabels: string[] = ["Utilization"];
  public doughnutChartData: number[] = [100];
  public areaChartLabels: string[] = [];
  public areaChartData: ChartDataSets[] = [
    { data: [0], label: "Power Consumption" }
  ];
  public statusChartOptions: any = {
    legend: {
      position: "right"
    }
  };
  public powerConsumptionChartOptions: any = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "kWh"
          }
        }
      ]
    }
  };
  activeTransaction: number;
  revenue: number;
  load: number;
  // public peakDemandChartData:number[] = [0];
  peakDemand: number;
  utilization: number;
  revenueSparklineChart: Chart;
  peakDemandSparklineChart: Chart;
  public pieChartType: string = "pie";
  public areaChartType: string = "line";
  public doughnutChartType: string = "doughnut";
  dateNow: Date = new Date();
  notifications: Array<object>;
  searchField = {
    startdate: this.dateNow,
    enddate: this.dateNow,
    stationId:0,
    chargerId:0,
    port:0,
  };
  utilChart: DemoModel[] = [];
  dateiso = this.dateNow.toLocaleDateString();
  //dateNowISO = this.dateNow.getDate()+'-'+this.dateNow.getMonth()+'-'+this.dateNow.getFullYear();
  pieChartColor: any = [
    {
      backgroundColor: ["rgb(93, 137, 168)", "rgb(160, 218, 82)"]
    }
  ];

  public areaChartLegend = true;
  areaChartColor: any = [
    {
      backgroundColor: ["rgb(93, 137, 168)"]
    }
  ];
  //centerText: true
  public pieChartOptions: any = {
    centerText: {
      display: true,
      text: "280"
    }
  };

  error: any = { isError: false, errorMessage: "" };
  isValidDate: any;
  chargerslist: Array<ManageChargerModel>;
  stationlist: Array<StationModel>;
  connectors:Array<PortModel>;
  station: StationModel;
  chargerdata:Array<ManageChargerModel>;
  charger:ManageChargerModel;
  stationData: StationModel;
  topbartext: TopbarText[] = [{ key: "dashboard", displayText: "Dashboard" }];
  public refreshInterval = interval(300000);
  private subscriptionLatestLoad;
  private subscriptionActiveUser;
  private subscriptionStatus;

  constructor(
    private stationservice: StationsService,
    private router : Router,  
    private objDashboardService: DashboardService,
    public tools: Tools
  ) {
    //this.fromDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.getStations();
    //this.getData();
    this.getLiveCp();
    this.getRevenue();
    this.getLiveTransaction();
    this.getUf();
    this.getTopLoad();
    this.getLineChart();
    this.getAlert();
    this.getLoad();

    this.subscriptionLatestLoad = this.refreshInterval.subscribe(() =>
      this.getLoad()
    );
    this.subscriptionActiveUser = this.refreshInterval.subscribe(() =>
      this.getLiveTransaction()
    );
    this.subscriptionStatus = this.refreshInterval.subscribe(() =>
      this.getLiveCp()
    );
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.subscriptionLatestLoad.unsubscribe();
    this.subscriptionActiveUser.unsubscribe();
    this.subscriptionStatus.unsubscribe();
  }
  configureKnobs(utilization) {
    this.utilChart = [];
    let config = {
      unit: "%",
      readOnly: true,
      size: 120,
      trackWidth: 20,
      barWidth: 15,
      barColor: "rgb(160, 218, 82)",
      trackColor: "#D8D8D8",
      textColor: "rgb(160, 218, 82)"
    };
    this.utilChart.push({
      name: "Utilization",
      value: utilization,
      options: new KnobModel(config),
      config: config
    });
    /* config: any = {
        unit: '%',
        readOnly: true,
        subText: {
            enabled: false,
            text: 'Utilization',
            color: 'gray',
            font: 'auto'
        },
        trackWidth: 40,
        barWidth: 25,
        trackColor: '#656D7F',
        barColor: '#2CC185'
    };
    this.utilChart.push({
        name: 'Utilization',
        value: utilization,
        options: new KnobModel(config),
        config: config
    });*/
  }

  revenueChart(data: ReadtsValue) {
    let chart = new Chart({
      chart: {
        backgroundColor: "white",
        borderWidth: 0,
        type: "area",
        margin: [2, 0, 2, 0],
        width: 320,
        height: 20,
        style: {
          overflow: "visible"
        }
      },
      title: {
        text: ""
      },
      xAxis: {
        categories: data.labels,
        endOnTick: true,
        startOnTick: true,
        labels: {
          enabled: true
        },
        visible: true
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: "Revenue"
        },
        tickPositions: [0]
      },
      legend: {
        enabled: false
      },
      tooltip: {
        hideDelay: 0,
        outside: true,
        shared: true
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2
              }
            }
          }
        },
        column: {
          negativeColor: "rgb(93, 137, 168)",
          borderColor: "silver"
        }
      },

      credits: {
        enabled: false
      },

      series: [
        {
          name: "Revenue",
          data: data.value,
          type: undefined,
          color: "rgb(93, 137, 168)"
        }
      ]
    });
    //chart.addPoint(4);
    this.revenueSparklineChart = chart;
    //chart.addPoint(5);
    /* setTimeout(() => {
      chart.addPoint(6);
    }, 2000);*/

    chart.ref$.subscribe(console.log);
  }

  peakDemandChart(data: ReadtsValue) {
    let chart = new Chart({
      chart: {
        backgroundColor: "white",
        borderWidth: 0,
        type: "area",
        margin: [2, 0, 2, 0],
        width: 320,
        height: 20,
        style: {
          overflow: "visible"
        }
      },
      title: {
        text: ""
      },
      xAxis: {
        categories: data.labels,
        labels: {
          enabled: true
        },
        title: {
          text: null
        },
        startOnTick: true,
        endOnTick: true
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      legend: {
        enabled: false
      },
      tooltip: {
        hideDelay: 0,
        outside: true,
        shared: true
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2
              }
            }
          }
        },
        column: {
          negativeColor: "#910000",
          borderColor: "silver"
        }
      },

      credits: {
        enabled: false
      },

      series: [
        {
          name: "peakDemand",
          data: data.value,
          type: undefined,
          color: "rgb(160, 218, 82)"
        }
      ]
    });
    //chart.addPoint(4);
    this.peakDemandSparklineChart = chart;
    //chart.addPoint(5);
    /*  setTimeout(() => {
      chart.addPoint(6);
    }, 2000);*/

    chart.ref$.subscribe(console.log);
  }

  // events on slice click
  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
        if ( activePoints.length > 0) {
          // get the internal index of slice in pie chart
          const clickedElementIndex = activePoints[0]._index;
          const label = chart.data.labels[clickedElementIndex];
          console.log(label)
          let status = "";
          if(label==="Online"){
            status = "Connected"
          }
          if(label==="Offline"){
            status = "disConnected"
          }
          this.router.navigate(['main/managecharger'],{ queryParams: { Status: status}});

        }
      }
  }

  // event on pie chart slice hover
  public chartHovered(e: any): void {
    console.log(e);
  }

  public getSerachResult() {
    if (this.validateDates(this.searchField)) {
      this.getRevenue();
      this.getUf();
      this.getTopLoad();
      this.getLineChart();
    }
  }

  getData() {
    this.objDashboardService.getData().subscribe(data => {
      /*this.areaChartLabels = data['result'].readts;
      let dateTime = [];
      for (var i = 0; i < this.areaChartLabels.length; i++) {
        dateTime.push(this.areaChartLabels[i].split(".")[0].replace("T"," "));
      }
      this.areaChartLabels = dateTime;
      this.areaChartData[0].data = data['result'].value;*/
    });
  }

  getLiveCp() {
    this.objDashboardService.getLiveCp(this.searchField.stationId,this.searchField.chargerId,this.searchField.port).subscribe(data => {
      this.pieChartData = data["result"]; // FILL THE CHART ARRAY WITH DATA.
    });
  }

  onActiveChargingClick(){
    //console.log("Hello World");
    //this.router.navigate
    this.router.navigate(['main/chargeraction']);
    
  }


  getRevenue() {
    this.objDashboardService.getRevenue(this.searchField).subscribe(data => {
      //this.revenue = data['result'];
      let tempData: ReadtsValue = new ReadtsValue();
      tempData.readts = data["result"].readts;
      tempData.value = data["result"].value;
      const revenueChartData = this.tools.getChartData(
        this.searchField.startdate,
        this.searchField.enddate,
        tempData
      );
      this.revenueChart(revenueChartData);
      var sum = 0;
      for (var i = 0; i < tempData.value.length; i++) {
        sum += tempData.value[i];
      }
      this.revenue = sum;
    });
  }

  getLineChart() {
    this.objDashboardService.getLineChart(this.searchField).subscribe(data => {
      let tempData: ReadtsValue = new ReadtsValue();
      tempData.readts = data["result"].readts;
      tempData.value = data["result"].value;
      const allDateData = this.tools.getChartData(
        this.searchField.startdate,
        this.searchField.enddate,
        tempData
      );
      this.areaChartLabels = allDateData.labels;
      /*let dateTime = [];
      for (var i = 0; i < this.areaChartLabels.length; i++) {
        dateTime.push(this.areaChartLabels[i].split(".")[0].replace("T"," "));
      }
      this.areaChartLabels = dateTime;*/
      ///this.areaChartData[0].data = data['result'].value;
      this.areaChartData[0].data = allDateData.value;
    });
  }

  getLiveTransaction() {
    this.objDashboardService.getLiveTransaction(this.searchField.stationId,this.searchField.chargerId,this.searchField.port).subscribe(data => {
      this.activeTransaction = data["result"];
      if (!this.activeTransaction) {
        this.activeTransaction = 0;
      }
    });
  }

  getUf() {
    this.objDashboardService.getUf(this.searchField).subscribe(data => {
      this.utilization = data["result"];
      this.configureKnobs(this.utilization);
    });
  }

  getTopLoad() {
    this.objDashboardService.getTopLoad(this.searchField).subscribe(data => {
      //this.peakDemand = data['result'];
      let tempData: ReadtsValue = new ReadtsValue();
      tempData.readts = data["result"].readts;
      tempData.value = data["result"].value;
      const allDateData = this.tools.getChartForTopItemMultiValSameDate(
        this.searchField.startdate,
        this.searchField.enddate,
        tempData
      );
      //this.peakDemandChartData = data['result'].value;
      this.peakDemandChart(allDateData);
      var sum = 0;
      var total = 0;
      for (var i = 0; i < tempData.value.length; i++) {
        if (tempData.value[i] > sum) {
          sum = tempData.value[i];
          total = total + tempData.value[i];
        }
        //        sum += tempData.value[i]
      }
      //this.peakDemand = sum;
      this.peakDemand = total;
      //this.peakDemand = 411.405343;
    });
  }

  getAlert() {
    this.objDashboardService.getAlert().subscribe(data => {
      this.notifications = data["result"];
    });
  }

  getLoad() {
    this.objDashboardService.getLoad(this.searchField.stationId,this.searchField.chargerId,this.searchField.port).subscribe(data => {
      this.load = data["result"];
      if (!this.load) {
        this.load = 0;
      }
    });
  }

  validateDates(searchField) {
    this.isValidDate = true;
    if (searchField.startdate == null || searchField.enddate == null) {
      this.error = {
        isError: true,
        errorMessage: "Start date and end date are required."
      };
      this.isValidDate = false;
    } else if (
      searchField.startdate != null &&
      searchField.enddate != null &&
      searchField.enddate < searchField.startdate
    ) {
      this.error = {
        isError: true,
        errorMessage: "End date should be grater then start date."
      };
      this.isValidDate = false;
    } else {
      this.error = { isError: false, errorMessage: "" };
      this.isValidDate = true;
    }
    return this.isValidDate;
  }


  async getStations() {
    await this.stationservice
      .getAllStationsWithSuccessorOperator()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let stations: Array<StationModel> = new Array<StationModel>();
          let station: StationModel = new StationModel();
          station.stationId = undefined;
          station.stationname = "All";
          stations.push(station);
          this.stationlist = data["result"];
        } else {
          console.error("Error in fetching alll stations");
        }
      });
  }
  setStation(){
      this.getDetail(this.searchField.stationId)
      this.getLiveTransaction();
      this.getLiveCp();
      this.getLoad();
      console.log(this.chargerslist);
  }
  setCharger(){
    this.getPorts(this.searchField.chargerId)
    this.getLiveTransaction();
      this.getLiveCp();
      this.getLoad();
     // console.log(this.chargerslist);
  }
  getPorts(chargerId){
    this.chargerdata = this.chargerslist.filter( obj => obj.chargerId == chargerId);
    console.log(this.chargerdata);
    this.connectors = this.tools.copyFullObject(this.chargerdata[0].chargerPort);


  }
  async getDetail(_id) {
    if (_id) {
      this.stationservice.getStationDetail(_id).subscribe(data => {
        if (data["success"]) {
          this.stationData = data["result"]; 
          if (this.stationData && this.stationData.managechargers) {
            this.chargerslist = this.tools.copyFullObject(this.stationData.managechargers);
         }  
         else {
          this.stationData = new StationModel();
          //alert("Failed to get station data!");
        } 
      }
      });
    }
      
  }
 


}

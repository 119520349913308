import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,ViewChild,
  DoCheck
} from "@angular/core";
import { Tools } from "src/app/modules/tools";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { AuthService } from "src/app/services/auth.service";
import { global } from "src/app/modules/global";
import {NavigationExtras, Router} from '@angular/router';
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "src/app/util/components/gridtable/gridtable.component";
import { DomSanitizer} from '@angular/platform-browser';
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/util/components/modalcomponent/modal-content";
import { HoursofdayModel } from "src/app/models/hoursofday.model";
import { StationModel } from "src/app/models/station.model";
import { ChargerService } from "src/app/services/charger.service";
import { ManageChargerService } from "src/app/services/ManageCharger.service";

import { ChargerModel } from "src/app/models/Charger.model";
import { StationsService } from "src/app/services/Stations.Service";
import { config } from 'process';
import { LogComponent } from "../log/log.component";
import { toBase64String } from "@angular/compiler/src/output/source_map";
import { TariffRuleModel } from "src/app/models/tariffrule.model";
import { PortModel } from "src/app/models/chargerPort.model";
import { IfStmt } from "@angular/compiler";
import { ThemeService } from "ng2-charts";


@Component({
  selector: "app-charger",
  templateUrl: "./charger.component.html",
  styleUrls: ["./charger.component.css"]
})
export class ChargerComponent implements OnInit {
  @ViewChild(LogComponent) logcomp: LogComponent;
  data: ManageChargerModel;
  _data: ManageChargerModel;
  priceByUnit:boolean=true;
  priceByMinute:boolean=false;
  priceByAmount:boolean=false;
  isOperatorScreen: boolean = false;
  isChargerScreen: boolean = false;
  _isSaved: boolean = false;
  selectedFile: File;
  page: GridPage;
  pele:string;
  obj: ManageChargerModel;
  port:number[]=[];
  chargerPortList: Array<PortModel>;
  _chargerPortList: Array<PortModel>;
  qrSRC:any;
  chargerport:[];
  showMsg:boolean;
  currentValue:string;
  datalist: Array<ManageChargerModel>;
  stationlist: Array<StationModel>;
  hoursOfDaylist:Array<HoursofdayModel>;
  tariffrulelist: Array<TariffRuleModel>;
  tarrifrule:TariffRuleModel;
  changeVal:String;
  chargingStatus:string;
  //chargerDisplayName:string;
  station: StationModel;
  searchField = {
    searchText: "",
  };
  selected:string;
  tab:boolean=false;
  freebox:string;
  closeBookingReboot:string;
  movable:string;
  showModal: boolean;
  showOcpiModal: boolean;
  command:[];
  showsmartcharging:boolean=false;
  showCommand:boolean;
  showMsgs:boolean;
  elep:string;
  chargerlist: Array<ChargerModel>;
  _disableSaveCancel: boolean = true;
  isScreen: boolean = false;
  isDetailScreen: boolean = true;
  locationModel : boolean;
  qrSelectedPort:number=1;
  qrChargerLabel:String;
  qrChargerName:String;
  qrStationID:number;
  qrModal : boolean;
  portModal : boolean;
  values2 = [];
  dropdownlocation = [];
  isChargerPortSaved:boolean=true;
  dropdownAssetcollection=[];
  dropdownSettings= {};

 conf=[];
 p=[];
  @Input()
  _param: any;
  @Input()
  _menuId: string;
  @Input()
  _action: string;
  @Output()
  saveEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  backClickEvent: EventEmitter<object> = new EventEmitter<object>();
  constructor(
    private tools: Tools,
    private router: Router,
    private sanitizer :DomSanitizer,
    private objGlobal: global,
    private auth: AuthService,
    private manageChargerService: ManageChargerService,
    private ngbModalService: NgbModal,
    public chargerservice: ChargerService,
    private stationservice: StationsService
  ) {
    this.dropdownAssetcollection=[{
    id:1,  name:"Unit"},
     {id:2, name:"Minute"
     
    }]
    this.dropdownSettings= {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.data = new ManageChargerModel();
  this.closeBookingReboot="yes";
  this.movable="no"
    this.getStations();
    this.getTariffs();
    this.alltimeperiodlist();
    this.station = new StationModel();
    this.tarrifrule = new TariffRuleModel();
    console.log(this._action);
  }
  hide()
  {
    this.showModal = false;
  }
  hideOc()
  {
    this.showOcpiModal = false;
  }
 
  
  selectedCompany=[];
  selectedStatus=[];
   
  
  ngOnInit() {
    console.log(this._action);
  }
  allowStationSelect() {
    let result: boolean = true;
    if (this._param && this._param.source !== "station") {
      result = false;
    }
    //console.log("Allow station select " + result);
    return result;
  }
  showSmart(){
    this.showsmartcharging=true;
  }
  showComm(){
    this.showCommand=true;
  }
  getlogforcharger(){
   // this.isDetailScreen=false;
   let navigationExtras: NavigationExtras={queryParams:{'val':'123'}}
  this.router.navigate(["/main/logs",{chargerId:this.data.chargerName}],);
   //this.logcomp.getlogforcharger();
   //this.logcomp.searchField.chargerI
   
  }
  closecharging(){
    this.showsmartcharging=false;
  }
  closeCommand(){
    this.showCommand=false;
    this.showMsgs=false;
    this.showMsg=false;
  }
 /* showMod()
  {
    this.showModal = true; 
    // Show-Hide Modal Check
  }*/
  showMod(){


  }
 gendisplayName(){
 // console.log(this.data.station.stationId);
//  console.log(this._action);
  let stationValue:any;
  if(this._action==="update"){
      if(this.data.label===null||this.data.label===undefined){ 
       /* if(this.data.station.stationId!==null||this.data.station.stationId!==undefined){
        stationValue = this.stationlist.filter(obj=>obj.stationId===this.data.station.stationId)
        }else */if(this.station.stationId!==undefined){
          stationValue =(this.stationlist.filter(obj=>obj.stationId===(Number(this.station.stationId))));
           this.data.label = (stationValue[0].stationname).replace(/ /g,"")+this.data.chargerName;
           }
           else if(this.station.stationId===null||this.station.stationId===undefined){
          stationValue[0].stationname = "";
        }
        if(this.data.chargerName!==null){
        console.log(stationValue[0].stationname);
        this.data.label = (stationValue[0].stationname).replace(/ /g,"")+this.data.chargerName;
        console.log(this.data.label)
      }
      
    }
    else{
      if((this.data.station.stationId!==null)  ||  this.data.station.stationId!==undefined){
        stationValue = this.stationlist.filter(obj=>obj.stationId===this.data.station.stationId)
        console.log(this.data.label)
        }
      this.data.label = (stationValue[0].stationname).replace(/ /g,"")+this.data.chargerName;
    }
  }
  if(this._action==="new"){
    console.log(this.station);
  if(this.station.stationId!==undefined){
     stationValue = this.stationlist.filter(obj=>obj.stationId===(Number(this.station.stationId)));
     // console.log(this.stationlist.filter(obj=>obj.stationId===(Number(this.station.stationId))));
      this.data.label = (stationValue[0].stationname).replace(/ /g,"")+this.data.chargerName;
      }
      else{
        this.data.label = this.data.chargerName;
      }
      console.log(this.data.label);
  }
  }
  savePorts(){
    
    this.data.chargerPort = this.tools.copyFullObject(this.chargerPortList);
    this.portModal=false;
    console.log(this.isChargerPortModified());
  }

  showPorts(){
    let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.size = 'lg';
    this.portModal=true;
  }

  hidePorts(){
    this.portModal=false;
  }

showQRCodes(){
   
    this.qrChargerName=this.data.chargerName;
    this.qrChargerLabel=this.data.label;
    this.qrStationID=this.data.station.stationId;
    this.generateQR();
    this.qrModal=true;
  }

  hideQRCodes(){
    this.qrModal=false;
  }
generateQR(){
 // let qrSRC:string;
  //this.qrSRC = "assets/QR/"+this.data.station.stationId+"\|"+this.data.chargerName+"\|"+this.qrSelectedPort+".png";
  const fileName = this.data.station.stationId+"\|"+this.data.chargerName+"\|"+this.qrSelectedPort+".png"
  this.manageChargerService.getQRCode(fileName)
  .subscribe(data => {
    if (data){
    var filename = fileName;
    var a = document.createElement("a");
    var blob = data;
    //this.qrcodeFile = fileName;
    var fileURL = window.URL.createObjectURL(data);
    //this.qrSRC = fileURL;
    this.qrSRC = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
    console.log(this.qrSRC );
}
}); 
}
  display()
  {
this.locationModel = false;
  }
  showOcpi()
  {
    
    this.showOcpiModal = true; 
    
  
  
    // Show-Hide OCPI Check
   
  }
  onAddNew() {
    this.tab=false;
    this._action = "new";
    this.data = new ManageChargerModel();
    this.data.priceBy = "";
    this.data.protocol = "OCPP";
    this.data.ocppVersion = "1.6";
    this.data.mode = "S";
    this.data.operationalHours="24by7"
    this.data.pricePerHour=0;
    this.data.pricePerMinute=0;
    this.data.price=0;
    this.station = new StationModel();
    
    this.data.chargingbyoptions="";
    
    this._data = this.tools.copyFullObject(this.data);
    this.isScreen = true;
    if (this.allowStationSelect()) {
      this.getStations();
    } else {
      this.station.stationId == undefined;
    }
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
    return isdelete;
  }
  isAddMode() {
    return this._action == "new";
  }

  isUpdate(){
    if(this._action=="update"){
      return true;
    }
    else false;
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }
  checkAction(){
    if(this._action=="update"){
      return true;
    }
    else false;
  }
 /*ngDoCheck() {
    this._disableSaveCancel=true;
    if(this.isModified()) {        
        if(this.isAllowAddorEdit()) {            
            this._disableSaveCancel=false;        
        }
    } 
}*/

  disableSave(f: any) {
    let isDisableSave: boolean = true;
   // console.log(f);
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }

  onBackbuttonClick() {
    this.conf=[];
    this.port=[];
    this.backClickEvent.emit({
      operatorId: this.data.chargerId,
      isModified: this.isModified()
    });

    //this.cancelClickEvent.emit({userId: this.user.userId,isModified : this.isUserModified()});
  }
onFileUpload(event){
  const uploadImageData = new FormData();
  let selectedFile = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(selectedFile);
  reader.onload = () => {
    let base64SelectedFile = <string>reader.result;
    var itemImagerawarray=base64SelectedFile.split(";");
     let  itemImagearray = itemImagerawarray[1].split(",");
      this.data.fileData = itemImagearray[1];
      console.log(itemImagearray[1]);
};
}

async getTariffs() {
  this.tariffrulelist = undefined;
  await this.manageChargerService
    .getAllTariffs("C")
    .toPromise()
    .then(data => {
      if (data["success"]) {
        let tariffrules: Array<TariffRuleModel> = new Array<TariffRuleModel>();
        let tariffrule: TariffRuleModel = new TariffRuleModel();
        tariffrule.ruleID = undefined;
        tariffrule.name = "-";
        tariffrules.push(tariffrule);
        const datatariffrule: Array<TariffRuleModel> = data["result"];
      //  console.log(datatariffrule);
        for (let i: number = 0; i < datatariffrule.length; i++) {
          tariffrules.push(datatariffrule[i]);
        }
        this.tariffrulelist = tariffrules;
       // console.log(this.tariffrulelist );
        this._data = this.tools.copyFullObject(this.data);
      } else {
        console.error("Error in fetching alll tariffs");
      }
    });
}

showFirmDetail(){
  //this.router.navigate(["/main/NewCharger",{chargerId:this.data.chargerName,}],);
  let ocpp_Id = this._data.chargerName;
  this.router.navigate(['main/NewCharger'],{ queryParams: { chargerId: ocpp_Id}});
  
}
hideStationDetailScreen() {
  let result: Boolean = true;
  if (this.isDetailScreen) {
    if (!this.isChargerScreen && !this.isOperatorScreen) {
      result = false;
    }
  }
  return result;
}
  getConfigfromEVSE() {
    console.log(this.changeVal)
    let  stationname= this._data.station;
    
    this.router.navigate(["/main/chargerconfig"],{queryParams:{stationId: stationname}});
    this.manageChargerService.getConfigFromEVSE(this.data.chargerName).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
       
        this.showMsgs=true;
        this.elep=data["result"]
      }
    });
         
  }
  SmartCharging(){

  }
  executeCommand() {
    console.log(this.changeVal)
    
    this.manageChargerService.saveCommand(this.data.chargerName,this.chargerport,this.command).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";


      if (data["success"]) {
       this.showMsg=true;
       this.pele=data["result"];
        
      }
    });
         
  }
  resetCommand() {
    console.log(this.changeVal)
    this.manageChargerService.resetCommand(this.data.chargerName,"Reset Charger").subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
       const modalRef = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRef.componentInstance.title = "Information";
      modalRef.componentInstance.message = "Command sent successfully!";
      modalRef.componentInstance.btnCLass = "btn-info";
      modalRef.result.then(
        result => {
    })
  }
      else if(data["error"]){
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "Connection Refused!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
      })
      }
    });
         
  }
  saveData() {
    if (this.tarrifrule) {
      this.data.tariffPlan= this.tarrifrule.ruleID;
    }else{
      this.data.tariffPlan=null;
    }
    
 /*  if(!this.data.chargerPort && this.data.number_of_chargingpoints>0){
      let chargerlist :Array<PortModel> = new Array<PortModel>();
      let chargerPortData  = new PortModel();
      
        chargerPortData.seqNumber=null;
        chargerPortData.identificationNumber=null;
        chargerPortData.qrCodeFilePath =null;
        chargerPortData.ocppStatus=null;
        chargerPortData.connectorType=null;
        chargerPortData.operational=null;
        chargerPortData.energyKWh=null;
        chargerPortData.scheduleID=null;
        chargerPortData.updatedTS=null;
        chargerPortData.usedSlots=null;
        chargerPortData.slotInterval=null;
        chargerPortData.isBusy=false
   
        this.data.chargerPort = chargerPortData;
        }*/
        console.log(this.data.chargingbyoptions)
      if(this.priceByMinute && this.priceByUnit && this.priceByAmount){
        this.data.chargingbyoptions='U,M,A'
      }else if(this.priceByUnit && !this.priceByMinute && !this.priceByAmount){
        this.data.chargingbyoptions='U'
      }
      else if(!this.priceByUnit && this.priceByMinute &&  !this.priceByAmount){
        this.data.chargingbyoptions='M'
      }
       else if(!this.priceByUnit && !this.priceByMinute &&  this.priceByAmount){
        this.data.chargingbyoptions='A'
      }
      else if(this.priceByUnit && this.priceByMinute &&  !this.priceByAmount){
        this.data.chargingbyoptions='U,M'
      }
       else if(this.priceByUnit && this.priceByAmount && !this.priceByMinute ){
        this.data.chargingbyoptions='U,A'
      }
       else if(!this.priceByUnit && this.priceByAmount && this.priceByMinute ){
        this.data.chargingbyoptions='M,A'
      }
      else if(!this.priceByUnit && !this.priceByMinute  && !this.priceByAmount){
        this.data.chargingbyoptions=null
      }
      
    this.data.othercharges=Math.round((this.data.othercharges + Number.EPSILON) * 100) / 100
    console.log(this.data.othercharges)
    this.data.discount=Math.round((this.data.discount + Number.EPSILON) * 100) / 100
    console.log(this.data.othercharges)
    if (this.station.stationId) {
      this.data.station = { stationId: this.station.stationId };
    }
    this.manageChargerService.saveCharger(this.data).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        this._isSaved = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "Charger saved successfully!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);

            if (result == "ok") {
              this.saveEvent.emit({ success: true, data: this.data });
              this.isDetailScreen = false;
            }
          },
          reason => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
          }
        );
      } else {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save Charger!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
        // alert("Operator not saved");
      }
    });
  }
  getDetail(_id) {
    if (_id) {
      this.manageChargerService.getChargerDetail(_id).subscribe(data => {
        if (data["success"]) {
          this._action = "update";
          this.data = data["result"];
          if (this.data.station) {
            this.station = { stationId: this.data.station.stationId };
          } else {
            this.station = new StationModel();
          }
          if (this.data && this.data.tariffPlan) {
            console.log(this.data.tariffPlan);
            this.tarrifrule.ruleID = this.data.tariffPlan;   
          } else {
            this.tarrifrule = new TariffRuleModel();
          }
          if(this.data.chargingbyoptions){
            let chargingbyoptions = this.data.chargingbyoptions.split(',');
            const priceByUnit = chargingbyoptions[0];
            const priceByMinute = chargingbyoptions[1];
            if(priceByUnit==='U'){
              this.priceByUnit=true;
            }
            if(priceByMinute==='M'){
              this.priceByMinute=true;
            }

          }
        
          this.tab=true;
          if(this.data.currentStatus="A"){
            this.chargingStatus="Active";
          }else{
            this.chargingStatus="Reserved";
          }
          var port=[];
          if(this.data.number_of_chargingpoints){
            let conlength=this.data.number_of_chargingpoints
          //  console.log(conlength)
           for(var i=1;i<=conlength;i++){
         port.push(i)
              
           }
           port.map((el)=>{
         //    console.log(el)
            var ob= Object.values(el)
           this.port.push(el);
            
           })
           //console.log(port);
          
          }
          
          const chargerPortList = this.data.chargerPort;
          //console.log(chargerPortList);
          this.getchargerPortData(chargerPortList);
          //this.chargerPortList = chargerPortList
          this._data = this.tools.copyFullObject(this.data);
        } else {
          alert("Failed to get ChargerPort list!");
        }
      });
    }
  }
  getchargerPortData(chargerPortlist:any){
    this.chargerPortList = chargerPortlist;
    this._chargerPortList = this.tools.copyFullObject(chargerPortlist);
   for(let i=0;i<this._chargerPortList.length;i++){
    console.log(this._chargerPortList[i].energyKWh)
    }

  }
  isChargerPortModified(){
    let modified = true;
    let energyKWh : Array<PortModel> = new Array<PortModel>();
    let connectorType : Array<PortModel> = new Array<PortModel>();
    let operational: Array<PortModel> = new Array<PortModel>();
  
      for(let j=0;j<this._chargerPortList.length;j++){
      energyKWh = this.chargerPortList.filter(obj => obj.identificationNumber === this._chargerPortList[j].identificationNumber && obj.energyKWh === this._chargerPortList[j].energyKWh );
      connectorType  = this.chargerPortList.filter(obj => obj.identificationNumber === this._chargerPortList[j].identificationNumber && obj.connectorType === this._chargerPortList[j].connectorType );   
       operational = this.chargerPortList.filter(obj => obj.identificationNumber === this._chargerPortList[j].identificationNumber && obj.operational === this._chargerPortList[j].operational );
      }
    
    console.log(energyKWh);
      if(energyKWh.length>0){
        modified = false;
        }
        if(connectorType.length>0){
          modified= false;
        }
        if(operational.length>0){
          modified =false;
        }
        console.log(modified);
       return modified
     
   
  }
  show(option:string){
    this.p.map((el)=>{
      let ob= Object.values(el)
      
      if(option==ob[0]){
        this.freebox= String(ob[2])  
          }
      
     })
  }

  dataTarrifPlan() {
    if (!this.data.tariffPlan) {
      return undefined;
    } else {
      return this.data.tariffPlan;
    }
  }
  
  isModified() {
  //console.log(this.data.tariffPlan);
  //console.log(this._data.tariffPlan);
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.chargerName != this._data.chargerName) {
      return true;
    } else if (this.data.label != this._data.label) {
      return true;
    } else if (this.data.chargerType != this._data.chargerType) {
      return true;
    }else if (this.data.category != this._data.category) {
      return true;
    }else if (this.data.connector != this._data.connector) {
      return true;
    } else if (this.data.outputType != this._data.outputType) {
      return true;
    } else if (this.data.config != this._data.config) {
      return true;
    }else if (this.data.outputType != this._data.outputType) {
      return true;
    }else if (this.data.protocol != this._data.protocol){
      return true;
    } else if (this.data.ocppVersion != this._data.ocppVersion){
      return true;
    }else if (this.data.deviceStatus != this._data.deviceStatus){
      return true;
    } else if (this.data.mode != this._data.mode){
      return true;
    }else if (this.data.ratedVoltages != this._data.ratedVoltages) {
      return true;
    }else if (this.data.number_of_chargingpoints != this._data.number_of_chargingpoints) {
      return true;
    }else if (this.data.chargerCapacity != this._data.chargerCapacity) {
      return true;
    } else if (this.data.operationalHours != this._data.operationalHours){
      return true;
    } else if (this.data.othercharges != this._data.othercharges) {
      return true;
    }  else if (this.data.discount != this._data.discount) {
      return true;
    }  else if (this.data.price != this._data.price) {
      return true;
    } else if (this.data.pricePerHour != this._data.pricePerHour) {
      return true;
    } else if (this.data.pricePerMinute != this._data.pricePerMinute) {
      return true;
    } else if (this.data.priceBy!= this._data.priceBy) {
      return true;
    } else if (this.data.chargingbyoptions != this._data.chargingbyoptions) {
      return true;
    }
    else if (this.data.station.stationId != this.dataStationId()) {
      return true;
    } else if (this.tarrifrule.ruleID != this.dataTarrifPlan()) {
      return true;
    } else if (this.data.config != this._data.config) {
      return true;
    }  else if (this.data.icon != this._data.icon){
      return true;
    } if( this.isChargerPortModified()){
      return true;
    }
    return false;
  
}
  async getChargerType() {
    const chargerdata: ChargerModel = await this.chargerservice.getChargerData(
      this.data.chargerType
    );

    if (chargerdata) {
      this.data.category = chargerdata.category;
      this.data.outputType = chargerdata.outputType;
      this.data.ratedVoltages = chargerdata.ratedVoltages;
      this.data.chargerCapacity = chargerdata.chargerCapacity;
      this.data.gunCapacity = chargerdata.gunCapacity;
      this.data.ocppVersion = chargerdata.ocppVersion;
    } else {
      this.data.category = undefined;
      this.data.outputType = undefined;
      this.data.ratedVoltages = undefined;
      this.data.chargerCapacity = undefined;
      this.data.gunCapacity = undefined;
      this.data.ocppVersion = undefined;
    }
  }
  dataStationId() {
    if (!this.data.station) {
      return undefined;
    } else if (!this.data.station.stationId) {
      return undefined;
    } 
    else if (this.station.stationId) {
      return this.station.stationId;
    }else {
      return this.data.station.stationId;
    }
  }

  setChargerTypeValue() {
    const objChargerType = this.chargerservice.getChargerDataByType(
      this.data.chargerType
    );

    this.data.chargerCapacity = this.data.chargerCapacity;
    this.data.category = this.data.category;
    this.data.gunCapacity = this.data.gunCapacity;
    this.data.chargerCapacity = this.data.chargerCapacity;
    this.data.outputType = this.data.outputType;
    this.data.ratedVoltages = this.data.ratedVoltages;
    this.data.ocppVersion = this.data.ocppVersion;
  }

  async getStations() {
    this.stationlist = undefined;
    await this.stationservice
      .getAllStations()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let stations: Array<StationModel> = new Array<StationModel>();
          let station: StationModel = new StationModel();
          station.stationId = undefined;
          station.stationname = "-";
          stations.push(station);
          const datastations: Array<StationModel> = data["result"];
          for (let i: number = 0; i < datastations.length; i++) {
            stations.push(datastations[i]);
          }
          this.stationlist = stations;
         // console.log(this.data.config);
        } else {
          console.error("Error in fetching alll stations");
        }
      });
  }
   async alltimeperiodlist() {
    this.hoursOfDaylist = undefined;
    await this.stationservice
      .alltimeperiodlist("C")
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let operationalHours: Array<HoursofdayModel> = new Array<HoursofdayModel>();
          let operationalHour: HoursofdayModel = new HoursofdayModel();
          operationalHour.id = undefined;
          operationalHour.name = "-";
          operationalHours.push(operationalHour);
          const dataoperationalHours: Array<HoursofdayModel> = data["result"];
          for (let i: number = 0; i < dataoperationalHours.length; i++) {
            operationalHours.push(dataoperationalHours[i]);
          }
          this.hoursOfDaylist = operationalHours;
         // console.log(this.data.config);
        } else {
          console.error("Error in fetching alll stations");
        }
      });
  }
  chargerStatusActionCaption() {
    let statusActionCaption: string = "Restart Charger";
    if (this.data.currentStatus == "R") {
      statusActionCaption = "Charging";
    }
    return statusActionCaption;
  }

  getActionButtonClass() {
    if (this.data.currentStatus == "R") {
      return "btn-danger";
    } else {
      return "btn-success";
    }
  }
  getActButtonClass() {
  return "btn-success";
   
  }
  showExportData(value)
  {
    if("Locations" == value){
    // alert("hello");
     this.dropdownlocation = ["noiad","Dehi"];

    }else if ("eMSP" == value){
      this.dropdownlocation = ["EmspData","EmspData1"];
    }else {
      this.dropdownlocation = ["HubData","Hubdata2"];
    }
    
  }
  printQR(){
    this.qrSRC = "assets/QR/"+this.data.station.stationId+"\|"+this.data.chargerName+"\|"+this.qrSelectedPort+".png";
    window.print();
  }

  
}
